// import React from "react";
// import { Link } from "gatsby";
// import PageWrapper from "../components/PageWrapper";

// import imgF1 from "../assets/image/l2/png/featured-job-logo-1.png";
// import iconD from "../assets/image/svg/icon-dolor.svg";
// import iconB from "../assets/image/svg/icon-briefcase.svg";
// import iconL from "../assets/image/svg/icon-location.svg";

// const JobDetails = () => {
//   return (
//     <>
//       <PageWrapper headerConfig={{ button: "profile" }}>
//         <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
//           <div className="container">
//             <div className="row justify-content-center">
//               {/* <!-- back Button --> */}
//               <div className="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
//                 <div className="mb-9">
//                   <Link to="/" className="d-flex align-items-center ml-4">
//                     {" "}
//                     <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
//                     <span className="text-uppercase font-size-3 font-weight-bold text-gray">
//                       Back to job board
//                     </span>
//                   </Link>
//                 </div>
//               </div>
//               {/* <!-- back Button End --> */}
//               <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
//                 <div className="bg-white rounded-4 border border-mercury shadow-9">
//                   {/* <!-- Single Featured Job --> */}
//                   <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
//                     <div className="row">
//                       <div className="col-md-6">
//                         {/* <!-- media start --> */}
//                         <div className="media align-items-center">
//                           {/* <!-- media logo start --> */}
//                           <div className="square-72 d-block mr-8">
//                             <img src={imgF1} alt="" />
//                           </div>
//                           {/* <!-- media logo end --> */}
//                           {/* <!-- media texts start --> */}
//                           <div>
//                             <h3 className="font-size-6 mb-0">
//                               Product Designer
//                             </h3>
//                             <span className="font-size-3 text-gray line-height-2">
//                               AirBnb
//                             </span>
//                           </div>
//                           {/* <!-- media texts end --> */}
//                         </div>
//                         {/* <!-- media end --> */}
//                       </div>
//                       <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
//                         {/* <!-- media date start --> */}
//                         <div className="media justify-content-md-end">
//                           <p className="font-size-4 text-gray mb-0">
//                             19 June 2020
//                           </p>
//                         </div>
//                         {/* <!-- media date end --> */}
//                       </div>
//                     </div>
//                     <div className="row pt-9">
//                       <div className="col-12">
//                         {/* <!-- card-btn-group start --> */}
//                         <div className="card-btn-group">
//                           <Link
//                             to="/#"
//                             className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
//                           >
//                             Apply to this job
//                           </Link>
//                           <Link
//                             to="/#"
//                             className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5"
//                           >
//                             <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
//                             Save job
//                           </Link>
//                         </div>
//                         {/* <!-- card-btn-group end --> */}
//                       </div>
//                     </div>
//                   </div>
//                   {/* <!-- End Single Featured Job --> */}
//                   <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
//                     <div className="row mb-7">
//                       <div className="col-md-4 mb-md-0 mb-6">
//                         <div className="media justify-content-md-start">
//                           <div className="image mr-5">
//                             <img src={iconD} alt="" />
//                           </div>
//                           <p className="font-weight-semibold font-size-5 text-black-2 mb-0">
//                             80-90K PLN
//                           </p>
//                         </div>
//                       </div>
//                       <div className="col-md-4 pr-lg-0 pl-lg-10 mb-md-0 mb-6">
//                         <div className="media justify-content-md-start">
//                           <div className="image mr-5">
//                             <img src={iconB} alt="" />
//                           </div>
//                           <p className="font-weight-semibold font-size-5 text-black-2 mb-0">
//                             Full-Time
//                           </p>
//                         </div>
//                       </div>
//                       <div className="col-md-4 pl-lg-0">
//                         <div className="media justify-content-md-start">
//                           <div className="image mr-5">
//                             <img src={iconL} alt="" />
//                           </div>
//                           <p className="font-size-5 text-gray mb-0">
//                             777 Brockton Avenue,{" "}
//                             <br className="d-md-none d-lg-block d-block" />
//                             Abington MA 2351
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-md-4 mb-lg-0 mb-10">
//                         <div className="">
//                           <span className="font-size-4 d-block mb-4 text-gray">
//                             Career Level
//                           </span>
//                           <h6 className="font-size-5 text-black-2 font-weight-semibold mb-9">
//                             Project Manangement
//                           </h6>
//                         </div>
//                         <div className="tags">
//                           <p className="font-size-4 text-gray mb-0">
//                             Soft Skill
//                           </p>
//                           <ul className="list-unstyled mr-n3 mb-0">
//                             <li className="d-block font-size-4 text-black-2 mt-2">
//                               <span className="d-inline-block mr-2">•</span>
//                               Slack
//                             </li>
//                             <li className="d-block font-size-4 text-black-2 mt-2">
//                               <span className="d-inline-block mr-2">•</span>
//                               Basic English
//                             </li>
//                             <li className="d-block font-size-4 text-black-2 mt-2">
//                               <span className="d-inline-block mr-2">•</span>Well
//                               Organized
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="col-md-4 pr-lg-0 pl-lg-10 mb-lg-0 mb-8">
//                         <div className="">
//                           <span className="font-size-4 d-block mb-4 text-gray">
//                             Type of corporation
//                           </span>
//                           <h6 className="font-size-5 text-black-2 font-weight-semibold mb-9">
//                             B2B &amp; B2C
//                           </h6>
//                         </div>
//                         <div className="tags">
//                           <p className="font-size-4 text-gray mb-3">
//                             Technical Skill
//                           </p>
//                           <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
//                             <li className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
//                               Editing
//                             </li>
//                             <li className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
//                               Wire-framing
//                             </li>
//                             <li className="bg-regent-opacity-15 mr-md-0 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
//                               XD
//                             </li>
//                             <li className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
//                               User Persona
//                             </li>
//                             <li className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
//                               Sketch
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="col-md-4 pl-lg-0">
//                         <div className="">
//                           <span className="font-size-4 d-block mb-4 text-gray">
//                             Company size
//                           </span>
//                           <h6 className="font-size-5 text-black-2 font-weight-semibold mb-0">
//                             11-50 employees
//                           </h6>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
//                     <div className="row">
//                       <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
//                         <div className="">
//                           <p className="mb-4 font-size-4 text-gray">
//                             Job Description
//                           </p>
//                           <p className="font-size-4 text-black-2 mb-7">
//                             Gubagoo is a fast growing provider of messaging and
//                             commerce solutions for automotive dealers changing
//                             the future of how people find, buy and service their
//                             vehicles.{" "}
//                           </p>
//                         </div>
//                         <div className="">
//                           <span className="font-size-4 font-weight-semibold text-black-2 mb-7">
//                             Your Role:
//                           </span>
//                           <p className="font-size-4 text-black-2 mb-7">
//                             We’re looking for a passionate individual to design
//                             beautiful and functional products for our customers
//                             at Gubagoo. We move very fast and you will be
//                             expected to contribute to a cross-functional product
//                             development squad, that includes product managers
//                             and developers, to deliver the UX and UI for the
//                             team to bring to life.{" "}
//                           </p>
//                           <p className="font-size-4 text-black-2 mb-7">
//                             We are serious about remote work. You can work for
//                             from anywhere.{" "}
//                           </p>
//                           <span className="font-size-4 font-weight-semibold text-black-2 mb-7">
//                             What you will be doing:
//                           </span>
//                           <ul className="list-unstyled">
//                             <li className="d-block font-size-4 text-black-2 d-flex flex-row mt-2">
//                               <span className="d-inline-block mr-7">•</span>
//                               Contribute new controls or design improvements to
//                               our
//                             </li>
//                             <li className="d-block font-size-4 text-black-2 d-flex flex-row mt-1">
//                               <span className="d-inline-block mr-7">•</span>Take
//                               ownership of the successful delivery of features
//                             </li>
//                             <li className="d-block font-size-4 text-black-2 d-flex flex-row mt-1">
//                               <span className="d-inline-block mr-7">•</span>Help
//                               set and achieve quarterly goals
//                             </li>
//                             <li className="d-block font-size-4 text-black-2 d-flex flex-row mt-1">
//                               <span className="d-inline-block mr-7">•</span>Ship
//                               a TON of product improvements and features
//                             </li>
//                           </ul>
//                           <Link
//                             to="/#"
//                             className="btn btn-green text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6"
//                           >
//                             Apply to this job
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };
// export default JobDetails;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { database, get, set, ref, push, onValue } from "../utils/firebase";

import imgF1 from "../assets/image/l2/png/featured-job-logo-1.png";
import iconD from "../assets/image/svg/icon-dolor.svg";
import iconB from "../assets/image/svg/icon-briefcase.svg";
import iconL from "../assets/image/svg/icon-location.svg";
import iconIR from "../assets/image/svg/icon-indrup.svg";
import ChatUI from "../components/ChatUI/ChatUI";

const JobDetails = ({ location, toggleChatVisibility }) => {

  const params = new URLSearchParams(location.search);
  const jobId = params.get("id");
  const [job, setJob] = useState([]);
  const isMountedRef = useRef(false);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showMoreExperience, setShowMoreExperience] = useState(false);
  const { user, isChatVisible, setJobProviderId, setJobSeekerId, handleClose, handleOpenChat } = useContext(GlobalContext);
  const gContext = useContext(GlobalContext);
  const [showMenuJobId, setShowMenuJobId] = useState(null);

  // Get logged-in user from cookies, make sure it's available before any action
  const loggedInUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

  const [messages, setMessages] = useState([]);

  const toggleMenuButton = (id) => {
    setShowMenuJobId(showMenuJobId === id ? null : id)
  }

  //  const { setJobProviderId, handleOpenChat } = useContext(GlobalContext);

  // const { isChatVisible, handleClose, handleOpenChat, user } = useContext(GlobalContext);
  // const handleChatButtonClick = async () => {
  //   if (!job || !job.posted_by || !job.posted_by.id) {
  //     console.error('Job provider ID not found');
  //     return;
  //   }

  //   if (!loggedInUser) {
  //     console.error('Logged-in user data not found in cookies');
  //     return;
  //   }

  //   const job_provider_id = job.posted_by.id;
  //   const job_seeker_id = loggedInUser.id;

  //   console.log('job_provider_id:', job_provider_id);
  //   console.log('job_seeker_id:', job_seeker_id);

  //   try {
  //     const response = await axiosConfig.post(`/chat-open/`, { job_seeker_id, job_provider_id });

  //     if (response.data.success) {
  //       setJob(response.data.data);
  //       console.log('This is the opened chat:', response.data.data);
  //     } else {
  //       setJob([]);
  //       console.error('Failed to fetch job details');
  //     }
  //   } catch (error) {
  //     setJob([]);
  //     console.error('Error fetching job details:', error);
  //   }

  //   if (!isChatVisible) {
  //     handleOpenChat();
  //   }
  // };

  const [userId, setUserId] = useState('');

  const placeholderImage = `${network.serverip}/images/No-job-Image-Placeholder.png`;

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);




  //const { isChatVisible, handleClose, handleOpenChat, user } = useContext(GlobalContext);
  // const handleChatButtonClick = async () => {
  //         var receiver_id=1;
  //         var sender_id=1;
  //         try {
  //           const response = await axiosConfig.post(`/chat-open/`, { receiver_id: receiver_id,sender_id:sender_id });
  //           if (response.data.success ) {
  //             setJob(response.data.data);
  //             console.log('This is the opened chat:', response.data.data);
  //           } else if (!response.data.success) {
  //             if (isMountedRef.current) {
  //               setJob([]);
  //             }
  //             console.error('Failed to fetch job details');
  //           }
  //         } catch (error) {
  //           if (isMountedRef.current) {
  //             setJob([]);
  //           }
  //           console.error('Error fetching job details:', error);
  //         }
  //   if (!isChatVisible) {
  //     handleOpenChat();
  //   }
  // };


  const fetchJobDetails = async () => {
    try {
      const response = await axiosConfig.post(`/jobdetails-forweb/${jobId}`, { userId: userId });
      if (response.data.success && isMountedRef.current) {
        setJob(response.data.data);
        console.log('This is the fetched job details:', response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setJob([]);
        }
        console.error('Failed to fetch job details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setJob([]);
      }
      console.error('Error fetching job details:', error);
    }
  };

  const handleJobSaveOrUnsave = async () => {
    try {

      if (!userId) {
        gContext.toggleSignInModal();

        return
      }

      if (userId && jobId) {
        const response = await axiosConfig.post(`/saveOrUnsaveJob-web`, { userId, jobId });

        if (response.data.success && isMountedRef.current) {

          setJob(prevState => ({
            ...prevState,
            isSaved: prevState.isSaved === 1 ? 0 : 1,
          }));

          console.log('Job saved / unsaved');
        } else {
          if (isMountedRef.current) {
            console.log('Failed to save / unsave job');
          }
          console.error('Failed to save / unsave job:', response.data.message);
        }
      } else {
        console.error('userId or jobId is missing');
      }
    } catch (error) {
      console.error('Failed to save/ unsave job:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    if (!jobId) {
      console.log('Waiting for the job id');
      return;
    }


    isMountedRef.current = true;

    fetchJobDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [jobId, userId]);

  const fetchChatMessages = async (job_provider_id, job_seeker_id) => {
    try {
      const messagesRef = ref(database, `chat/messages`);
      const snapshot = await get(messagesRef);
      const data = snapshot.val();

      if (data) {
        const filteredMessages = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .filter(
            (message) =>
              (message.job_seeker_id === job_seeker_id && message.job_provider_id === job_provider_id) ||
              (message.job_seeker_id === job_provider_id && message.job_provider_id === job_seeker_id)
          );

        setMessages(filteredMessages);
        console.log("Chat messages fetched:", filteredMessages);
      } else {
        console.warn("No chat messages found between the users.");
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };





  const handleChatButtonClick = async () => {
    if (!job?.posted_by?.id || !user?.id) {
      console.error("Missing user or job provider information");
      return; // Don't proceed if required IDs are missing
    }

    const job_provider_id = job.posted_by.id;
    const job_seeker_id = user.id;

    console.log('job_provider_id in job detail page:', job_provider_id);

    // Make the Axios request only if both IDs are valid
    try {
      const response = await axiosConfig.post(`/chat-open/`, { job_seeker_id, job_provider_id });
      if (response.data.success) {
        console.log("Chat opened:", response.data.data);

        // Set the IDs in the context for the Chat UI
        setJobProviderId(job_provider_id);
        setJobSeekerId(job_seeker_id);

        // Fetch and display the chat messages
        fetchChatMessages(job_provider_id, job_seeker_id);
      } else {
        console.error("Failed to open chat:", response.data.message);
      }
    } catch (error) {
      console.error("Error opening chat:", error);
    }

    if (!isChatVisible) {
      handleOpenChat();
    }
  };




  const styles = JobDetailsStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="jobDetails-section bg-default-1 pt-32 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              {/* <!-- back Button --> */}
              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/joblisting');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
              {/* <!-- back Button End --> */}
              <div className="col-xl-12 col-lg-12">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  {/* <!-- Single Featured Job --> */}
                  <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-4 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      {/* <div className="col-12 pt-5 pt-2">
                        <div className="d-flex justify-content-end">
                          <div className="flex-shrink-0 ml-7">
                            <p className="font-size-4 text-gray mb-0">
                              {job?.created_at ? `Posted on: ${new Date(job.created_at).toLocaleString('default', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric'
                              })}`
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-12 pt-3 d-flex justify-content-between align-items-start">
                        {/* <!-- media start --> */}
                        <div className="media align-items-center">
                          {/* <!-- media logo start --> */}
                          <button
                            onClick={() => gContext.toggleImageViewModal(job?.image)}
                            style={styles.imageViewButton}
                          >
                            <div className="square-72 d-flex align-items-center rounded-3 mr-8" style={styles.imageContainer}>
                              <img
                                src={job && job.image ? imageBaseUrl + job.image : placeholderImage}
                                alt=""
                                style={styles.jobImage}
                              />
                            </div>
                          </button>
                          {/* <!-- media logo end --> */}
                          {/* <!-- media texts start --> */}
                          <div>
                            <h3 className="font-size-6 mb-0">
                              {job?.title || ''}
                              <span className="text-success ml-5">
                                {job?.featured_job === 1 ? '(Featured Job)' : ''}
                              </span>
                            </h3>
                            <p className="font-size-4 text-gray line-height-2">
                              <span className="mr-2">
                                <FontAwesomeIcon icon={faHospitalAlt} />
                              </span>
                              {job?.institution?.title || ''}
                            </p>
                          </div>
                          {/* <!-- media texts end --> */}
                        </div>
                        {/* <!-- media end --> */}

                        {gContext.isJobProvider === 0 &&
                          <div>
                            {showMenuJobId === job?.id &&
                              <>
                                {job?.isReported === 1 ?
                                  (
                                    <button
                                      style={styles.reportButton}
                                      className="font-size-4 text-gray"
                                    >
                                      <i className="fas fa-info-circle text-gray"></i>
                                      <span className="text-gray ml-5">Already Reported</span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => { gContext.toggleReportJobModal(job?.id) }}
                                      style={styles.reportButton}
                                      className="font-size-4 text-danger"
                                    >
                                      <i className="fas fa-info-circle text-danger"></i>
                                      <span className="text-danger ml-5">Report</span>
                                    </button>
                                  )
                                }
                              </>
                            }
                            <button
                              onClick={() => { toggleMenuButton(job?.id) }}
                              style={styles.openMenuIcon}
                            >
                              <i className="fas fa-ellipsis-v ml-5"></i>
                            </button>
                          </div>
                        }

                      </div>
                    </div>
                    {gContext.isJobProvider === 0 &&
                      <div className="row pt-9">
                        <div className="col-12">
                          {/* <!-- card-btn-group start --> */}
                          <div className="card-btn-group d-flex">
                            {job?.isApplied === 1 ? (
                              <>
                                {/* Group 1 */}
                                <div className="parentclass">
                                  {/* <button
                                            className="btn btn-gray text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
                                            disabled
                                          >
                                            Already Applied
                                          </button> */}

                                  {/* <button
                                            onClick={handleChatButtonClick}
                                            className="btn btn-gray text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
                                          >
                                            Chat
                                          </button> */}
                                  {/* {job?.posted_by?.id && user?.id && (
  
                                <>
                                {console.log("Job provider IDd:", job.posted_by.id)}

                                <button
                                  onClick={handleChatButtonClick}
                                  className="btn btn-gray text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
                                >
                                  Chat
                                </button> 
                              </>
                                )}
                                {isChatVisible && job?.posted_by?.id && user?.id && (
                                  <ChatUI jobProviderId={job.posted_by.id} jobSeekerId={user.id} />
                                )} */}


                                </div>

                                {/* Group 2 */}
                                <div>
                                  <button
                                    onClick={handleChatButtonClick}
                                    className="btn btn-success text-uppercase h-px-48 rounded-3 mb-5 px-5 mr-5"
                                  >
                                    Chat
                                  </button>
                                  <button
                                    className="btn btn-gray text-uppercase h-px-48 rounded-3 mb-5 px-5 mr-5"
                                    disabled
                                  >
                                    Applied
                                  </button>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={`/job-apply/?id=${job?.id}`}
                                className="btn btn-success text-uppercase h-px-48 rounded-3 mb-5 px-5 mr-5"
                              >
                                Apply
                              </Link>
                            )}

                            {/* Save Job Section */}
                            <div>
                              {job && job.isSaved === 1 ? (
                                <button
                                  onClick={handleJobSaveOrUnsave}
                                  className="btn btn-outline-mercury text-uppercase h-px-48 rounded-3 mb-5 px-5"
                                >
                                  <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                                  <span className="text-success">Saved</span>
                                </button>
                              ) : (
                                <button
                                  onClick={handleJobSaveOrUnsave}
                                  className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5"
                                >
                                  <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                  Save job
                                </button>
                              )}
                            </div>
                          </div>
                          {/* <!-- card-btn-group end --> */}
                        </div>
                      </div>
                    }

                    {job?.posted_by?.name && (
                      <div className="w-100 mt-2 mb-2 d-flex">
                        <div className="flex-shrink-0">
                          Posted by:
                        </div>
                        <div className="flex-grow-1 ml-3">
                          <Link to={`/company-profile-details/?id=${job.posted_by.id}`}
                            className="font-size-4 text-dodger" style={{ textDecoration: 'underline' }}>
                            {`${job.posted_by.name}`}
                          </Link>
                        </div>
                      </div>
                    )}

                    {job?.created_at && (
                      <div className="w-100 mt-2 mb-2 d-flex">
                        <div className="flex-shrink-0">
                          Posted on:
                        </div>
                        <div className="flex-grow-1 ml-3">
                          <p className="font-size-4 text-gray mb-0">
                            {job?.created_at ? `${new Date(job.created_at).toLocaleString('default', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric'
                            })}`
                              : ''}
                          </p>
                        </div>
                      </div>
                    )}

                    {job?.job_expiry_date && (
                      <div className="w-100 mt-2 mb-2 d-flex">
                        <div className="flex-shrink-0">
                          Expires on:
                        </div>
                        <div className="flex-grow-1 ml-3">
                          <p className="font-size-4 text-gray mb-0">
                            {job?.job_expiry_date ? `${new Date(job.job_expiry_date).toLocaleString('default', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric'
                            })}`
                              : ''}
                          </p>
                        </div>
                      </div>
                    )}

                  </div>
                  {/* <!-- End Single Featured Job --> */}
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row mb-7">
                      {/* <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconIR} alt="" />
                          </div>
                          <p className="font-weight-semibold font-size-5 text-black-2 mb-0">
                            {job?.salary ? `${job.salary}/month` : 'Salary not available'}
                          </p>
                        </div>
                      </div> */}
                      <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconB} alt="" />
                          </div>
                          <p className="font-weight-semibold font-size-5 text-black-2 mb-0">
                            {job?.job_type?.title || 'Job type not available'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconL} alt="" />
                          </div>
                          <p>
                            <span className="font-weight-semibold font-size-5 text-black-2 mb-0">
                              {job?.location?.title || 'Location not available'}
                            </span>
                            {/* <br className="d-md-none d-lg-block d-block" /> */}
                            {/* <span className="font-size-3 text-black-2 mb-0">
                              {job?.state?.title ? `State: ${job.state.title}` : ''}
                            </span> */}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          {/* <div className="image mr-5">
                            <img src={iconL} alt="" />
                          </div> */}
                          <p>
                            <span className="font-weight-semibold font-size-5 text-black-2 mb-0">
                              {job?.state?.title ? `State: ${job.state.title}` : ''}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-lg-0 mb-10">
                        <div className="">
                          <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                            Category
                          </span>
                          <p className="font-size-4 text-black-2 mb-9 font-weight-semibold">
                            {job?.job_category?.title || 'Category not available'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-lg-0 mb-10">
                        <div className="">
                          <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                            Qualification
                          </span>
                          <p className="font-size-4 text-black-2 mb-9 font-weight-semibold">
                            {job?.qualification?.title || 'Qualification not available'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-lg-0 mb-10">
                        <div className="">
                          <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                            Experience in Years
                          </span>
                          <p className="font-size-4 text-black-2 mb-0 font-weight-semibold">
                            {job && job.experience_in_months !== undefined
                              ? [`${(job.experience_in_months / 12).toFixed(1)} year(s)`]
                              : ['Experience in years not available']
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-lg-0 mb-10">
                        <div className="tags">
                          <p className="font-size-4 text-gray font-weight-bold mb-3">
                            Skills
                          </p>
                          <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                            {job && job.skills && job.skills.length > 0 ? (
                              job.skills.map((skill, index) => (
                                <li key={index} className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-4 font-weight-semibold text-black-2 mt-2">
                                  {skill.title}
                                </li>
                              ))
                            ) : (
                              <li className="d-block font-size-4 text-black-2 mt-2">Skills not available</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        <div className="">
                          <p className="mb-4 font-size-4 text-gray">
                            Description
                          </p>
                          <p className="font-size-4 text-black-2 mb-7" style={{ textAlign: 'justify' }}>
                            {job && job.description ? (
                              <span>
                                {(showMoreDescription || job.description.length <= 150)
                                  ? `${job.description} `
                                  : `${job.description.slice(0, 150)}... `}
                                {job.description.length > 150 && (
                                  <button onClick={() => setShowMoreDescription(!showMoreDescription)} style={styles.showMoreButton}>
                                    {showMoreDescription ? 'Show less' : 'Show more'}
                                  </button>
                                )}
                              </span>
                            ) : (
                              <span>Description not available</span>
                            )}
                          </p>
                        </div>
                        <div className="">
                          <p className="mb-4 font-size-4 text-gray">
                            Experience
                          </p>
                          <p className="font-size-4 text-black-2 mb-7" style={{ textAlign: 'justify' }}>
                            {job && job.experience ? (
                              <span>
                                {(showMoreExperience || job.experience.length <= 150)
                                  ? `${job.experience} `
                                  : `${job.experience.slice(0, 150)}... `}
                                {job.experience.length > 150 && (
                                  <button onClick={() => setShowMoreExperience(!showMoreExperience)} style={styles.showMoreButton}>
                                    {showMoreExperience ? 'Show less' : 'Show more'}
                                  </button>
                                )}
                              </span>
                            ) : (
                              <span>Experience not available</span>
                            )}
                          </p>
                        </div>

                        {gContext.isJobProvider === 0 &&
                          <div>
                            {job?.isApplied === 1 ? (
                              <button className="btn btn-gray text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6" disabled>
                                Applied
                              </button>
                            ) : (
                              <Link
                                to={`/job-apply/?id=${job?.id}`}
                                className="btn btn-green text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6"
                              >
                                Apply
                              </Link>
                            )}
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default JobDetails;

function JobDetailsStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      outline: 'none',
    },

    imageContainer: {
      backgroundColor: '#f5f5f5',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
    },
    jobImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    skillItem: {
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      maxWidth: '100%',
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    openMenuIcon: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },
    reportButton: {
      position: 'absolute',
      right: '2.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }
  };

  return styles;
}

